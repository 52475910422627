import { useAtom } from 'jotai'
import { AnimatePresence } from 'framer-motion'
import { QueryParams, useQueryParam } from 'use-query-params'
import { useLayoutEffect, useState } from 'preact/hooks'

import {
  InterfaceViewAtom,
  AppModeAtom,
  PreviousViewAtom,
  AppScope,
} from 'contexts'

import Icon from '../Icon/Icon'

import { translateStringKey, gtmPush, analytics_modeSwitch } from 'utils'
import { SUITS_MODE, SEPERATES_MODE, VIEW_MODE, LOCALSTORAGE_KEY } from 'data'

import {
  Container,
  Thumb,
  MotionContainer,
  IconWrapper,
  Backdrop,
  TooltipWrapper,
  TooltipLabel,
} from './ModeToggle.styles'

const ModeToggle = ({
  locale,
  initialOpenLayer,
  disabled,
  trackingMetadata,
}) => {
  const [appMode, setAppMode] = useAtom(AppModeAtom, AppScope)
  const [interfaceView] = useAtom(InterfaceViewAtom)
  const [, setPreviousView] = useAtom(PreviousViewAtom)

  const [, setViewMode] = useQueryParam(VIEW_MODE, QueryParams)

  const isLayerMode = appMode === SEPERATES_MODE

  const [showGuide, setShowGuide] = useState(!disabled)

  useLayoutEffect(() => {
    const willShowGuide = !window.localStorage.getItem(LOCALSTORAGE_KEY)
    setShowGuide(willShowGuide)

    if (willShowGuide && !interfaceView) {
      gtmPush({
        event: 'GAevent',
        eventCategory: 'Crackthecode_Interactions',
        eventAction: 'Display_Tooltip',
        eventLabel: `${translateStringKey(
          `toggle-${isLayerMode ? 'layers' : 'suits'}`,
          'en'
        )}`,
      })
    }
  }, [interfaceView, isLayerMode])

  const promptKey = translateStringKey(
    `toggle-${isLayerMode ? 'layers' : 'suits'}`,
    locale
  )

  const closeOverlay = () => {
    window.localStorage.setItem(LOCALSTORAGE_KEY, true)
    setShowGuide(false)
  }

  const handleToggle = () => {
    if (disabled) return
    // states
    setAppMode((prev) =>
      prev === SEPERATES_MODE ? SUITS_MODE : SEPERATES_MODE
    )
    setViewMode(appMode === SEPERATES_MODE ? SUITS_MODE : SEPERATES_MODE)

    // interaction
    // setTimeout(() => {
    //   closeOverlay()
    // }, 500)

    // misc
    setPreviousView(undefined)

    // analytics
    gtmPush({
      event: 'GAevent',
      eventCategory: 'Crackthecode_Interactions',
      eventAction: `${
        appMode !== SEPERATES_MODE ? 'Switch_To_Separate' : 'Switch_To_Suit'
      }`,
      eventLabel: `${initialOpenLayer}`,
    })

    analytics_modeSwitch(
      {
        ...trackingMetadata,
        lookbuilderOption: `Switch_to_${
          appMode !== SEPERATES_MODE ? 'Separate' : 'Suit'
        }`,
      },
      appMode === SEPERATES_MODE ? SUITS_MODE : SEPERATES_MODE
    )
  }

  return (
    <AnimatePresence exitBeforeEnter={showGuide}>
      <Container
        key="mode-toggle"
        $disabled={interfaceView}
        role="switch"
        aria-label={`Switch to ${isLayerMode ? 'suit' : 'layer'} mode`}
        aria-checked={isLayerMode}
        tabindex={isLayerMode ? '-1' : '0'}
        aria-hidden={isLayerMode ? 'false' : 'true'}
        onClick={handleToggle}
        onMouseDown={(e) => e.preventDefault()}
        onKeyPress={(e) => {
          if (e.nativeEvent.key === ' ') handleToggle()
        }}
        animate={{
          opacity: !interfaceView ? 1 : 0,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          opacity: 0,
        }}
        tabIndex={0}
      >
        {showGuide && (
          <TooltipWrapper
            key="tooltip"
            initial={{
              opacity: 0,
              y: -10,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: -10,
            }}
          >
            <TooltipLabel key="tooltip-label">{promptKey}</TooltipLabel>
          </TooltipWrapper>
        )}

        <Thumb
          key="thumb"
          $transformTrigger={isLayerMode}
          $disabled={disabled}
        >
          <MotionContainer
            key="motion-container"
            initial={false}
            animate={{
              transform: isLayerMode ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: {
                duration: 0.45,
                ease: [0.4, 0.03, 0, 1],
                type: 'tween',
              },
            }}
          >
            <IconWrapper
              key="top-icon"
              initial={false}
              animate={{
                transform: isLayerMode ? 'translateX(0%)' : 'translateX(75%)',
                transition: {
                  duration: 0.25,
                  ease: [0.4, 0.03, 0, 1],
                  type: 'tween',
                },
              }}
            >
              <Icon icon="toggleMode" />
            </IconWrapper>
            <IconWrapper
              key="bottom-icon"
              initial={false}
              style={{
                transformOrigin: 'bottom right',
              }}
              animate={{
                transform: isLayerMode ? 'translateX(0%)' : 'translateX(-60%)',
                opacity: isLayerMode ? 1 : 0,
                transition: {
                  duration: 0.5,
                  ease: [0.4, 0.03, 0, 1],
                  type: 'tween',
                },
              }}
            >
              <Icon icon="ToggleMode" />
            </IconWrapper>
          </MotionContainer>
        </Thumb>
      </Container>

      {!interfaceView && showGuide && (
        <Backdrop
          key="backdrop"
          onClick={closeOverlay}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 0.2,
          }}
          exit={{
            opacity: 0,
          }}
        />
      )}
    </AnimatePresence>
  )
}

export default ModeToggle
