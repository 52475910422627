import useSWR from 'swr'
import { useAtom } from 'jotai'
import styled from 'styled-components'
import { useEffect, useRef, useState, useContext } from 'preact/hooks'
import { QueryParamProvider } from 'use-query-params'
import { useAnimation, AnimatePresence } from 'framer-motion'

import Model from 'components/Model'
import Toast from 'components/Toast'
import Scanner from 'components/Scanner'
import Interface from 'components/Interface'
import ScanButton from 'components/ScanButton'
import ModeToggle from 'components/ModeToggle'
import Placeholder from 'components/Placeholder'
import DataProvider from 'components/DataProvider'
import ProductsProvider from 'components/ProductsProvider'
import InterfaceProvider from 'components/InterfaceProvider'

import { mediaQueries, GlobalStyle } from 'styles'

import useHeightSize from 'hooks/useHeightSize'
import useLocaleCheck from 'hooks/useLocaleCheck'
import useConfiguration from 'hooks/useConfiguration'

import {
  gtmPush,
  getSingleCategory,
  getSWRConfig,
  analytics_segmentTrackingFirstLoad,
} from 'utils'

import {
  LoadingImages,
  AppScope,
  isAppLoaded,
  TimeOutTime,
  ProductMatrixContext,
  GlobalProductsContext,
  PortalAtom,
  ScannedProductAtom,
  AuthContext,
} from 'contexts'

import {
  SUITS_MODE,
  TOP_LAYER,
  SUIT_LAYER,
  SEPERATES_MODE,
  OPTIONAL_LAYERS,
  PORTAL_SCANNER_KEY,
} from 'data'
import { Fragment } from 'preact'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100 - var(--header-mobile));
  position: relative;

  ${mediaQueries.m} {
    flex-direction: row;
    height: ${({ $isHighResTablet, $isTablet }) => {
      if ($isHighResTablet) return `calc(100vh - var(--header-tablet-highres));`
      if ($isTablet) return `calc(100vh - var(--header-tablet));`
      return `calc(100vh - var(--header));`
    }};
  }
`

const START_TIMER = 0

const QueryAdapter = ({ children }) => {
  const adapter = {
    replace(location) {
      window.history.replaceState(location.state, '', location.search || '?')
    },
    push(location) {
      window.history.pushState(location.state, '', location.search || '?')
    },
    get location() {
      return window.location
    },
  }

  return children(adapter)
}

// Portals/Overlays
const PORTALS = {
  [`${PORTAL_SCANNER_KEY}`]: Scanner,
}

export default function App({
  initialModel = 4,
  initialProducts,
  initialSuitProducts,
  site,
  submitUrl,
  backUrl,
  initialOpenLayer,
  initialViewMode = SEPERATES_MODE,
  dataSource,
  localeFromParam,
}) {
  const { locale, countryCode, siteID, currencyCode, priceFormat } = site

  const authContext = useContext(AuthContext)
  const isKOL = Boolean(authContext?.account)

  const { swrParams, fetcher, revalidateConfig } = getSWRConfig({
    dataSource,
    siteID,
    locale,
    countryCode,
    currencyCode,
    initialProducts,
    initialSuitProducts,
    localeFromParam,
  })

  const hasOptionalLayers = initialProducts
    .map((e) => OPTIONAL_LAYERS.includes(e.layer))
    .filter((e) => e)
  const imagesNumber = 12 + hasOptionalLayers.length
  const locales = { locale: `${locale}-${countryCode}`, siteID }

  const trackingMetadata = {
    currency: currencyCode?.toLowerCase(),
    country: countryCode?.toLowerCase(),
    language: locale,
    locationId: siteID,
    userId: '',
    loggedIn: `${Boolean(isKOL)}`,
    pageType: 'lookbuilder',
    lookbuilderOption: '',
    url: '',
  }

  const { isValidating, data: groupedData } = useSWR(
    swrParams,
    fetcher,
    revalidateConfig
  )

  const [showGuide, setShowGuide] = useState(
    localStorage.getItem('guideClosed') !== 'true'
  )
  const [selectSkin, setSelectSkin] = useState(
    localStorage.getItem('modelSelectedNew')?.includes('model')
  )
  const [postalSelection] = useAtom(PortalAtom, AppScope)
  const [isLoaded, setIsLoaded] = useAtom(isAppLoaded, AppScope)
  const [imageLoad, setImageLoad] = useAtom(LoadingImages, AppScope)
  const [, setTimeoutTime] = useAtom(TimeOutTime, AppScope)
  const [scannedProduct] = useAtom(ScannedProductAtom, AppScope)

  const isTablet = useRef()
  const isHighResTablet = useRef()
  const canLoadSuitsMode = useRef(true)

  const { shouldRevertToDefaults } = useLocaleCheck({ locales }) // ensure that locale swaps mid-session don't break the app
  const controls = useAnimation()
  useHeightSize()

  useEffect(() => {
    // handles the edge case where chrome on ipad pro is adding more height than safar; these conditions set the right compensation height for the header
    const isChrome = /CriOS/.test(navigator.userAgent)
    const isSafari = !isChrome && /Safari/.test(navigator.userAgent)

    isTablet.current = navigator.maxTouchPoints > 0
    isHighResTablet.current = !isSafari && isChrome
  }, [])

  useEffect(() => {
    setImageLoad((prev) => (prev += 1))
  }, [initialModel, setImageLoad])

  const {
    OCAPIData,
    suitProducts,
    separateProducts,
    modelSkin,
    setModelSkin,
    defaultProducts,
    productMatrix,
    appMode,
    data: tempArray,
    setGlobalProductSet,
  } = useConfiguration({
    groupedData,
    isValidating,
    shouldRevertToDefaults,
  })

  useEffect(() => {
    if (isLoaded) {
      const prompt = getSingleCategory(
        appMode === SUITS_MODE && initialOpenLayer === TOP_LAYER
          ? SUIT_LAYER
          : initialOpenLayer
      )

      // analytics
      gtmPush({
        event: 'GAevent',
        eventCategory: 'Crackthecode_Interactions',
        eventAction: `Start_${appMode === SUITS_MODE ? 'Suit' : 'Separate'}`,
        eventLabel: `${prompt}`,
      })

      analytics_segmentTrackingFirstLoad(
        {
          ...trackingMetadata,
          url: window.location.href,
          lookbuilderOption: 'Crackthecode_Interactions',
        },
        appMode
      )
    }
  }, [isLoaded])

  useEffect(() => {
    //TODO remove with new model selection functionality
    localStorage.removeItem('modelSelected')
    if (imageLoad >= imagesNumber) {
      setIsLoaded(true)
    }
  }, [imageLoad])

  useEffect(() => {
    if (!isLoaded) return
    controls.start({ opacity: 1 })
    setTimeoutTime(performance.now() - START_TIMER)
  }, [isLoaded, controls])

  const appIsLoading = !(defaultProducts && OCAPIData && productMatrix)
  const initialProductSet =
    appMode === SUITS_MODE ? suitProducts : separateProducts

  const PortalComponent = PORTALS[postalSelection] || (() => <></>)

  return (
    <QueryParamProvider adapter={QueryAdapter}>
      <Container
        $isTablet={isTablet.current}
        $isHighResTablet={isHighResTablet.current}
      >
        <GlobalStyle />
        {appIsLoading ? (
          <Placeholder />
        ) : (
          <GlobalProductsContext.Provider
            value={{ globalProductSet: OCAPIData, setGlobalProductSet }}
          >
            <ProductMatrixContext.Provider value={{ productMatrix }}>
              <AnimatePresence>{!isLoaded && <Placeholder />}</AnimatePresence>
              <ProductsProvider
                site={site}
                initialProducts={separateProducts}
                initialSuitProducts={suitProducts}
              >
                <AnimatePresence>
                  {isLoaded ? (
                    <ModeToggle
                      locale={locale}
                      initialOpenLayer={initialOpenLayer}
                      disabled={!canLoadSuitsMode.current}
                      trackingMetadata={trackingMetadata}
                    />
                  ) : null}
                </AnimatePresence>

                <AnimatePresence>
                  <Fragment key="portalcomponent">
                    <PortalComponent />
                  </Fragment>

                  <Fragment key="toast">{scannedProduct && <Toast />}</Fragment>

                  <Fragment key="scanbutton">
                    {isKOL && <ScanButton />}
                  </Fragment>
                </AnimatePresence>

                <Model
                  submitUrl={submitUrl}
                  controls={controls}
                  modelSkin={modelSkin}
                  initialProducts={initialProductSet}
                  locales={locales}
                  trackingMetadata={trackingMetadata}
                />
                <DataProvider globalData={tempArray}>
                  <InterfaceProvider>
                    <Interface
                      priceFormat={priceFormat}
                      locales={locales}
                      controls={controls}
                      isLoaded={isLoaded}
                      selectSkin={selectSkin}
                      showGuide={showGuide}
                      setShowGuide={setShowGuide}
                      setModelSkin={setModelSkin}
                      backUrl={backUrl}
                      modelSkin={modelSkin}
                      initialOpenLayer={initialOpenLayer}
                      initialProducts={initialProductSet}
                      initialViewMode={appMode || initialViewMode}
                      trackingMetadata={trackingMetadata}
                    />
                  </InterfaceProvider>
                </DataProvider>
              </ProductsProvider>
            </ProductMatrixContext.Provider>
          </GlobalProductsContext.Provider>
        )}
      </Container>
    </QueryParamProvider>
  )
}
