import { useAtom } from 'jotai'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { QueryParams, useQueryParam } from 'use-query-params'
import {
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'preact/hooks'

import Save from 'components/Save'
import Products from 'components/Products'
import Categories from 'components/Categories'
import { Notice } from 'components/ResetButton'
import EmptyCategory from 'components/EmptyCategory'
import ProductDescription from 'components/ProductDescription'

import {
  Modes,
  AppScope,
  AppModeAtom,
  LoadingImages,
  AcceptSelection,
  InterfaceViewAtom,
  TranslationsAtom,
  GlobalProductsContext,
  HorizontalScrollContext,
} from 'contexts'

import {
  gtmPush,
  getJourney,
  formatState,
  getSingleCategory,
  translateStringKey,
  getProductDescription,
  analytics_productConfirmed,
} from 'utils'

import {
  colors,
  transitions,
  mediaQueries,
  interfaceSize,
  hideScrollbars,
  fadeInAnimation,
  delayedFadeInAnimation,
} from 'styles'

import {
  VIEW_MODE,
  TOP_LAYER,
  SUITS_MODE,
  SUIT_LAYER,
  MODEL_LAYER,
  BOTTOM_LAYER,
  OPTIONAL_LAYERS,
  SEPERATES_MODE,
  OUTOFSTOCK_STRING,
} from 'data'

const InterfaceWrapper = styled(motion.div)`
  position: relative;
  transition: height 200ms ${transitions.general.css};
  height: ${({ $isModelLayer }) =>
    `${interfaceSize.mobile + ($isModelLayer ? 30 : 0)}px`};
  opacity: var(--header-mobile);
  touch-action: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    z-index: 10;
    border-radius: 5px;
    background-color: ${colors.white};
    opacity: ${(props) => (!props.selectSkin ? '0' : '1')};
    transition: opacity 0ms ease;
    transition-delay: 500;
  }

  ${mediaQueries.s} {
    height: ${({ $isModelLayer }) =>
      `${interfaceSize.tablet + ($isModelLayer ? 65 : 0)}px`};

    &:after {
      height: ${interfaceSize.tablet}px;
    }
  }

  ${mediaQueries.m} {
    display: block;
    width: ${interfaceSize.desktop}px;
    height: 100%;

    &:after {
      height: ${interfaceSize.desktop}px;
    }
  }
`

const ButtonsWrapper = styled(motion.div)`
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: ${({ $isModelLayer }) =>
    $isModelLayer ? `15px 15px 0 10px` : `0 13px 14px 15px`};
  z-index: 11;
  position: relative;

  position: absolute;

  bottom: -5px;
  right: 0;
  width: 100%;

  ${({ $isModelLayer }) =>
    $isModelLayer &&
    `
    position: absolute;
    top: 0;
    bottom: unset;
    `};

  * {
    flex-shrink: 0;
  }

  ${mediaQueries.m} {
    position: absolute;
    top: unset;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 10px;
  }
`
const ThumbnailsWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 11;
  background-color: ${colors.white};

  ${mediaQueries.s} {
    height: ${interfaceSize.tablet}px;
  }

  ${mediaQueries.m} {
    top: unset;
    transform: unset;
    margin-top: unset;
    padding-bottom: ${({ $isProductLayer }) =>
      !!$isProductLayer ? '62px' : '0px'};

    margin-bottom: ${({ $isProductLayer }) =>
      !!$isProductLayer ? '10px' : '0px'};
    height: 100%;
    overflow-y: ${({ $isModelLayer }) => ($isModelLayer ? 'hidden' : 'scroll')};
  }

  ${hideScrollbars}
`

const ActionContainer = styled(motion.div)`
  ${mediaQueries.m} {
    width: 100%;
  }
`

const Interface = ({
  controls,
  isLoaded,

  selectSkin,
  showGuide,
  setShowGuide,
  setModelSkin,

  initialOpenLayer,
  initialProducts,
  initialViewMode,

  modelSkin,
  locales,
  priceFormat,

  trackingMetadata,
}) => {
  // states
  const [appMode] = useAtom(AppModeAtom, AppScope)
  const [currentView, setCurrentView] = useAtom(InterfaceViewAtom)
  const [category, setCategory] = useState(false)

  const productDescription = useRef()

  // writables
  const [, setAcceptSelection] = useAtom(AcceptSelection)
  const [, setImageLoad] = useAtom(LoadingImages, AppScope)

  const { JacketsAtom, ShirtsAtom, TrousersAtom, ShoesAtom, CoatsAtom } =
    Modes[appMode]

  const [, setViewMode] = useQueryParam(VIEW_MODE, QueryParams)
  const [suitParams, setSuitParams] = useQueryParam(SUITS_MODE, QueryParams)
  const [seperatesParams, setSeperatesParams] = useQueryParam(
    SEPERATES_MODE,
    QueryParams
  )

  // readables
  const [translations] = useAtom(TranslationsAtom, AppScope)
  const [jackets] = useAtom(JacketsAtom)
  const [shirts] = useAtom(ShirtsAtom)
  const [trousers, setTrousers] = useAtom(TrousersAtom)
  const [shoes] = useAtom(ShoesAtom)
  const [coats] = useAtom(CoatsAtom)

  // variable states
  const products = useMemo(
    () => [jackets, shirts, trousers, shoes, coats],
    [coats, jackets, shirts, shoes, trousers]
  )
  const isSuitsMode = appMode === SUITS_MODE
  const currentProducts = useMemo(() => products, [category])
  const isHorizontalScroll = useContext(HorizontalScrollContext)
  const { globalProductSet } = useContext(GlobalProductsContext)

  // helpers
  const [currentProduct, setCurrentProduct] = useState(false)
  const canReset = OPTIONAL_LAYERS.includes(currentProduct?.layerName)
  const isModelLayer = currentView === 'ModelSelection'
  const isOutOfStock = currentView === OUTOFSTOCK_STRING
  const renderStatusLabel = isHorizontalScroll && !isModelLayer

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search)
    const viewModeParamExists = searchParam.get(VIEW_MODE)
    if (!viewModeParamExists) setViewMode(initialViewMode)
  }, [])

  useEffect(() => {
    if (category?.layerName) {
      const [selectedProduct] = products.filter(
        (el) => el.layerName === category.layerName && !el.isFallback
      )
      setCurrentProduct(selectedProduct)
      productDescription.current = getProductDescription(
        selectedProduct,
        appMode,
        globalProductSet
      )
    }

    return () => setCurrentProduct(false)
  }, [category, currentProducts, products])

  useEffect(() => {
    if (!initialOpenLayer) return

    setCategory(getJourney(initialOpenLayer))
    setShowGuide(false)
  }, [])

  useEffect(() => {
    if (isOutOfStock) {
      setCategory(false)
    }
  }, [currentView, isOutOfStock])

  useEffect(() => {
    setCurrentView(category?.category)
  }, [category, setCurrentView])

  // event handlers
  const handleClickAccept = useCallback(
    (e) => {
      e.preventDefault()

      if (category.layerName !== MODEL_LAYER) {
        const [selectedProduct] = products.filter(
          (el) => el.layerName === category.layerName
        )

        if (!selectedProduct) {
          setCategory(false)
          return
        }

        const mainCategory = getSingleCategory(
          isSuitsMode && Boolean(selectedProduct?.parentId)
            ? SUIT_LAYER
            : selectedProduct.layerName
        )

        const prompt = translateStringKey(mainCategory, 'en')

        gtmPush({
          event: 'GAevent',
          eventCategory: 'Crackthecode_Interactions',
          eventAction: 'Product_Selected',
          eventLabel: `${prompt}`,
        })

        if (isSuitsMode) {
          setSuitParams((pre) => {
            const isTopLayer = selectedProduct.layerName === TOP_LAYER
            const parent = globalProductSet.find(
              (e) => selectedProduct.parentId === e.id
            )
            const counterpart = parent?.setProducts.find(
              (e) => e.layerName === BOTTOM_LAYER
            )

            // handle suit modes
            if (isTopLayer && isSuitsMode) {
              setTrousers(counterpart)
            }

            return formatState(
              pre,
              suitParams,
              isTopLayer ? parent : selectedProduct,
              globalProductSet
            )
          })
        } else {
          setSeperatesParams((pre) => {
            return formatState(
              pre,
              seperatesParams,
              selectedProduct,
              globalProductSet
            )
          })
        }
        setAcceptSelection(selectedProduct)

        // analytics
        analytics_productConfirmed({
          ...trackingMetadata,
          lookbuilderOption: selectedProduct?.parentId || selectedProduct?.id,
        })
      }

      setCategory(false)
    },
    [category, products]
  )

  const handleImagesLoad = useCallback(() => {
    setImageLoad((prev) => prev + 1)
  }, [setImageLoad])

  return (
    <>
      <InterfaceWrapper
        $isModelLayer={isModelLayer}
        $canReset={canReset}
        isCategory={!category}
        selectSkin={selectSkin}
        showGuide={showGuide}
      >
        <ThumbnailsWrapper
          $isProductLayer={category}
          $isModelLayer={isModelLayer}
        >
          <AnimatePresence exitBeforeEnter>
            {!isOutOfStock && !category && (
              <Categories
                locales={locales}
                setCategory={setCategory}
                controls={controls}
                isLoaded={isLoaded}
                onLoad={handleImagesLoad}
                key="categorySection"
                setShowGuide={setShowGuide}
                initialProducts={initialProducts}
                trackingMetadata={trackingMetadata}
              />
            )}
            {!isOutOfStock && category && (
              <Products
                layerName={category.layerName}
                categoryIndex={category.index}
                key="productsSection"
                currentProduct={currentProduct}
                locales={locales}
                modelSkin={modelSkin}
                setModelSkin={setModelSkin}
                initialProducts={initialProducts}
                priceFormat={priceFormat}
                trackingMetadata={trackingMetadata}
              />
            )}
            {isOutOfStock && !category && <EmptyCategory locales={locales} />}
          </AnimatePresence>
        </ThumbnailsWrapper>

        <AnimatePresence exitBeforeEnter>
          {category && (
            <ButtonsWrapper
              $isModelLayer={isModelLayer}
              isProducts={category}
              initial="initial"
              animate="animate"
              exit="initial"
              variants={delayedFadeInAnimation}
            >
              {
                <ActionContainer {...fadeInAnimation}>
                  {renderStatusLabel && currentProduct && (
                    <ProductDescription
                      priceFormat={priceFormat}
                      productData={productDescription.current}
                      animationTrigger={currentProduct?.id}
                      currencySign={currentProduct?.currencySign}
                    />
                  )}

                  {renderStatusLabel && !currentProduct && (
                    <Notice
                      layerName={category.layerName}
                      locales={locales}
                    />
                  )}
                  <Save
                    isModelLayer={isModelLayer}
                    category={category}
                    handleClickAccept={handleClickAccept}
                    translations={translations}
                    locales={locales}
                  />
                </ActionContainer>
              }
            </ButtonsWrapper>
          )}
        </AnimatePresence>
      </InterfaceWrapper>
    </>
  )
}

export default Interface
