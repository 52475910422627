import {
  OPTIONAL_LAYERS,
  TOP_LAYER,
  BOTTOM_LAYER,
  SUIT_LAYER,
  COAT_LAYER,
  LAYER_MAPPING,
  SUIT_LAYER_MAPPING,
  PLACEHOLDER_PRODUCT,
  ORIGIN_STORE,
  SORT_OBJECT,
} from 'data'

import {
  formatProductData,
  formatStoreProductData,
  MAIN_GROUP_URL,
  STORE_PRODUCTS_URL,
  MATRIX_URL,
  fetcherGroupProducts,
  fetchStoreProducts,
} from 'utils'

/**
 * Formats and returns OCAPI data
 * @param {Array} data - array of products
 * @param {Object} PRODUCT_TYPE - object of product types
 * @returns {Array} - array of formatted products
 */
export const getFormattedOCAPIData = (data, PRODUCT_TYPE_IDS) =>
  data.map((product) => {
    const layerName = PRODUCT_TYPE_IDS[product.c_onlineProductTypeIDCode]
    const baseProduct = formatProductData({
      product,
      PRODUCT_TYPE_IDS,
    })

    const isSuitLayer = layerName === SUIT_LAYER && product?.set_products
    const isNestedSuit = layerName === SUIT_LAYER && !product?.set_products

    // sets product set for nested suit products
    const setNestedSet = (_, index) => {
      if (!LAYER_MAPPING[index]) return

      return {
        ...baseProduct,
        parentId: baseProduct.id,
        isNestedSuit,
        layerName: LAYER_MAPPING[index],
      }
    }

    // sets product set for Mix and match suit products
    const setMixAndMatchSet = (e) => {
      const setProduct = formatProductData({ product: e, PRODUCT_TYPE_IDS })

      return {
        ...setProduct,
        isNestedSuit,
        parentId: baseProduct.id,
      }
    }

    const obj = {
      ...baseProduct,
      ...(isNestedSuit && { isNestedSuit }),
      ...(isNestedSuit && {
        setProducts: [...Array(5)].map(setNestedSet).filter((x) => x),
      }),
      ...(isSuitLayer && {
        setProducts: product?.set_products.map(setMixAndMatchSet),
      }),
    }

    return obj
  })

/**
 *
 * @param {Array} tempArray - array of arrays of products
 * @param {Array} firstProducts - array of first products (passed by json)
 * @param {Boolean} isStore - boolean to check if store, force sets toplayer
 * @returns {Array} - array of products with default products
 */
export const getDefaultProducts = (tempArray, firstProducts, isStore) =>
  tempArray.map((batch) => {
    const isOptionalLayer = OPTIONAL_LAYERS.includes(batch[0].layerName)
    const isNotInitialProduct = !firstProducts.find(
      ({ layer }) => layer === batch[0].layerName
    )

    // legacy, used to be part of `isOptional` conditions
    const isNotStoreOrTopLayer = !isStore || batch[0].layerName !== TOP_LAYER

    const isOptional =
      isOptionalLayer && isNotStoreOrTopLayer && isNotInitialProduct

    const match = batch.find((prod) => prod.enabledForDefaultLook) || batch[0]

    return {
      ...match,
      ...{ isFallback: isOptional },
    }
  })

/**
 * Function to get the default products for the suit mode
 * @param {Array} tempArray - array of arrays of products
 * @param {Array} firstSuitProducts - array of first products (passed by json)
 * @param {Array} OCAPIData - formatted OCAPI data
 * @returns {Array} - array of products with default suit products
 */
export const getDefaultSuitProducts = (
  tempArray,
  firstSuitProducts,
  OCAPIData
) => {
  // defaullt suit as set by OCAPI
  const defaultSuitSet = OCAPIData.find(
    (e) => e.enabledForDefaultLook && e.layerName === SUIT_LAYER
  )

  // falls back to the first products that is a valid suit
  let fallbackSuit = OCAPIData.find((e) => e.layerName === SUIT_LAYER)

  // if no valid suit is found, fallback to the first products
  const defaultSuit = defaultSuitSet || fallbackSuit

  return tempArray.map((batch, index) => {
    const layerName = LAYER_MAPPING[index]

    const isOptional =
      OPTIONAL_LAYERS.includes(batch[0].layerName) &&
      !firstSuitProducts?.find(({ layer }) => layer === batch[0].layerName)

    if (!defaultSuit) return PLACEHOLDER_PRODUCT

    let match

    // grab suit product or non-suit products
    if ([0, 2].includes(index)) {
      match =
        defaultSuit.setProducts.find((e) => e.layerName === layerName) ||
        fallbackSuit.setProducts.find((e) => e.layerName === layerName) ||
        batch[0]
    } else {
      match = batch.find((prod) => prod?.enabledForDefaultLook) || batch[0]
    }

    return { ...match, ...{ isFallback: index !== 0 && isOptional } }
  })
}

/**
 * Gets the initial products for the separate mode
 * @param {Array} tempArray - array of arrays of products
 * @param {Array} firstProducts  - array of first products (passed by json)
 * @param {Array} firstProductsMap  - array of first product id's
 * @param {Array} defaultProducts - array of default products
 * @returns {Array} - array of products with initial products
 */
export const getInitialProducts = (
  tempArray,
  firstProducts,
  firstProductsMap,
  defaultProducts
) =>
  tempArray.map((batch, index) => {
    const isOptional =
      OPTIONAL_LAYERS.includes(batch[0].layerName) &&
      !firstProducts?.find(({ layer }) => layer === batch[0].layerName)

    const match =
      batch.find((prod) => firstProductsMap?.includes(prod.id) && prod) ||
      defaultProducts[index] ||
      tempArray[index][0]

    return { ...match, ...{ isFallback: isOptional } }
  })

/**
 * Gets the initial products for the suit mode
 * @param {Array} tempArray - array of arrays of products
 * @param {Array} firstSuitProducts - array of first products (passed by json)
 * @param {Array} OCAPIData - formatted OCAPI data
 * @returns {Array} - array of initial products for suit mode
 */
export const getInitialSuitProducts = (
  tempArray,
  firstSuitProducts,
  defaultSuitProducts,
  OCAPIData
) => {
  const initialSuit = firstSuitProducts?.find((e) => e.layer === SUIT_LAYER)

  const suitProduct =
    (initialSuit && OCAPIData.find((e) => e.id === initialSuit?.id)) ||
    OCAPIData.find((e) => e.id === defaultSuitProducts[0]?.parentId)

  return tempArray.map((batch, index) => {
    // focusses only on coat layer
    const isOptional =
      OPTIONAL_LAYERS.includes(batch[0].layerName) &&
      !!firstSuitProducts?.find(({ layer }) => layer === COAT_LAYER)

    // Only the toplayer can be optional here anyway
    const isFallback = index !== 0 && isOptional
    const layerName = LAYER_MAPPING[index]

    // layers are suit layers, return parts of the initial suit product
    if (suitProduct && layerName) {
      const match = suitProduct.setProducts.find(
        (e) => e.layerName === layerName
      )
      return { ...match, ...{ isFallback } }
    }

    const suitLayerName = SUIT_LAYER_MAPPING[index]
    const target = firstSuitProducts.find((e) => e.layer === suitLayerName)
    const match = OCAPIData.find((e) => e.id === target?.id) || batch[0]

    return { ...match, ...{ isFallback } }
  })
}

/**
 * Checks if a suit is valid; contains a jacket and trousers
 * @param {Object} suit - suit object
 * @returns {Boolean} - true if suit is complete
 */
export const isValidSuit = (suit) => {
  if (!suit?.setProducts) return false

  const { setProducts } = suit

  const hasJacket = Boolean(setProducts.find((e) => e.layerName === TOP_LAYER))
  const hasTrousers = Boolean(
    setProducts.find((e) => e.layerName === BOTTOM_LAYER)
  )

  return Boolean(hasJacket && hasTrousers)
}

/**
 * Function gets cookie value by cookie name
 * @param {String} name - name of cookie
 * @returns {String} - cookie value
 */
export const getCookie = (name) => {
  let nameEQ = name + '='
  let ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const getSWRConfig = ({
  dataSource,
  siteID,
  locale,
  countryCode,
  currencyCode,
  initialProducts = [],
  initialSuitProducts = [],
  localeFromParam,
}) => {
  const isStore = dataSource === ORIGIN_STORE

  const revalidateConfig = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  }
  const webstoreUrl = MAIN_GROUP_URL(locale, countryCode, siteID, currencyCode)
  const storeUrl = STORE_PRODUCTS_URL(localeFromParam)

  const swrParams = isStore
    ? [storeUrl, MATRIX_URL]
    : [webstoreUrl, MATRIX_URL, initialProducts, initialSuitProducts]

  // fetcher
  const fetchFunction = isStore ? fetchStoreProducts : fetcherGroupProducts

  return { swrParams, fetcher: fetchFunction, revalidateConfig }
}

export const getFormattedStoreProductData = (data, PRODUCT_TYPE_IDS) => {
  const normalizedData = data.map((product) => {
    const baseProduct = formatStoreProductData({ product, PRODUCT_TYPE_IDS })

    const isNestedSuit = baseProduct.layerName === SUIT_LAYER

    // handle nested products accordingly; add sets
    const setNestedSet = (_, index) => {
      if (!LAYER_MAPPING[index]) return

      return {
        ...baseProduct,
        isNestedSuit,
        layerName: LAYER_MAPPING[index],
        parentId: baseProduct.id,
      }
    }

    const formattedProduct = {
      ...baseProduct,
      ...(isNestedSuit && { isNestedSuit }),
      ...(isNestedSuit && {
        setProducts: [...Array(5)].map(setNestedSet).filter((x) => x),
      }),
      ...(isNestedSuit && { parentId: baseProduct.id }),
    }

    return formattedProduct
  })

  return normalizedData
}
